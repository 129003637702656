var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageLoader',{attrs:{"storage":_vm.appLoading}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-img',{style:({
      height:
        _vm.$vuetify.breakpoint.name == 'xs'
          ? '100%'
          : _vm.$vuetify.breakpoint.name == 'sm'
          ? '100%'
          : _vm.$vuetify.breakpoint.name == 'md'
          ? '700px'
          : _vm.$vuetify.breakpoint.name == 'lg'
          ? '700px'
          : '700px',
    }),attrs:{"src":require("./../../assets/images/backHome1.jpg")}},[_c('div',{style:({
        'padding-top':
          _vm.$vuetify.breakpoint.name == 'xs'
            ? '0px'
            : _vm.$vuetify.breakpoint.name == 'sm'
            ? '0px'
            : _vm.$vuetify.breakpoint.name == 'md'
            ? '50px'
            : _vm.$vuetify.breakpoint.name == 'lg'
            ? '50px'
            : '50px',
      })},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-xs-0":"","pt-sm-0":"","pt-md-16":"","pt-lg-16":"","pt-xl-16":""}},[_c('v-flex',{attrs:{"xs10":"","sm12":"","text-center":"","pt-16":""}},[_c('span',{staticStyle:{"font-family":"sedanRegular","color":"#7cb9e8"},style:({
              'font-size':
                _vm.$vuetify.breakpoint.name == 'xs'
                  ? '50px'
                  : _vm.$vuetify.breakpoint.name == 'sm'
                  ? '50px'
                  : _vm.$vuetify.breakpoint.name == 'md'
                  ? '70px'
                  : _vm.$vuetify.breakpoint.name == 'lg'
                  ? '70px'
                  : '70px',
            })},[_vm._v("Welcome to ECOLedger ")])]),_c('v-flex',{attrs:{"xs12":"","sm8":"","md5":"","text-center":"","py-5":""}},[_c('span',{staticStyle:{"font-family":"scopeOneRegular","color":"#000000","font-size":"16px"}},[_vm._v("ECOLedger is an application for the recording, storing and publishing of time-sensitive Green Finance related data in a manner that is simple and unchangeable ")])])],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs10":"","sm8":"","md6":"","lg4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},_vm._l((_vm.items),function(item,i){return _c('v-flex',{key:i,attrs:{"xs6":"","sm3":"","md3":"","pa-3":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"bg",attrs:{"rounded":"lg","elevation":"5","height":"110px","width":"110px"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out black darken-2 v-card--reveal white--text",staticStyle:{"height":"100%","font-size":"13px","color":"white !important","font-family":"opensansregular !important"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()]),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","pa-2":"","pt-3":""}},[_c('v-img',{attrs:{"src":item.img,"contain":"","height":"80"}})],1)],1)],1)]}}],null,true)}),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","py-5":""}},[_c('span',{staticStyle:{"font-family":"scopeOneRegular","color":"#000000","font-size":"16px"}},[_vm._v(_vm._s(item.name))])])],1)],1)}),1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"scopeOneRegular","color":"#000000","font-size":"16px"}},[_vm._v("Record, Store and Publish securely for the Environment ")])])],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }