<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center py-md-0>
      <v-flex>
        <WelcomePage />
      </v-flex>
      <v-flex>
        <About />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import WelcomePage from "./welcomePage";
import About from "./about";

export default {
  components: {
    WelcomePage,
    About,
  },
  props: ["storage"],

  data() {
    return {
      appLoading: false,
      ServerError: false,
      sideNav: true,
    };
  },
  beforeMount() {},
  methods: {},
};
</script>
