<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-img
      src="./../../assets/images/backHome1.jpg"
      :style="{
        height:
          $vuetify.breakpoint.name == 'xs'
            ? '100%'
            : $vuetify.breakpoint.name == 'sm'
            ? '100%'
            : $vuetify.breakpoint.name == 'md'
            ? '700px'
            : $vuetify.breakpoint.name == 'lg'
            ? '700px'
            : '700px',
      }"
    >
      <div
        :style="{
          'padding-top':
            $vuetify.breakpoint.name == 'xs'
              ? '0px'
              : $vuetify.breakpoint.name == 'sm'
              ? '0px'
              : $vuetify.breakpoint.name == 'md'
              ? '50px'
              : $vuetify.breakpoint.name == 'lg'
              ? '50px'
              : '50px',
        }"
      >
        <v-layout
          wrap
          justify-center
          pt-xs-0
          pt-sm-0
          pt-md-16
          pt-lg-16
          pt-xl-16
        >
          <v-flex xs10 sm12 text-center pt-16>
            <span
              style="font-family: sedanRegular; color: #7cb9e8"
              :style="{
                'font-size':
                  $vuetify.breakpoint.name == 'xs'
                    ? '50px'
                    : $vuetify.breakpoint.name == 'sm'
                    ? '50px'
                    : $vuetify.breakpoint.name == 'md'
                    ? '70px'
                    : $vuetify.breakpoint.name == 'lg'
                    ? '70px'
                    : '70px',
              }"
              >Welcome to ECOLedger
            </span>
          </v-flex>
          <v-flex xs12 sm8 md5 text-center py-5>
            <span
              style="
                font-family: scopeOneRegular;
                color: #000000;
                font-size: 16px;
              "
              >ECOLedger is an application for the recording, storing and
              publishing of time-sensitive Green Finance related data in a
              manner that is simple and unchangeable
            </span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs10 sm8 md6 lg4>
            <v-layout wrap justify-center>
              <v-flex xs6 sm3 md3 v-for="(item, i) in items" :key="i" pa-3>
                <v-hover v-slot="{ hover }">
                  <v-card
                    rounded="lg"
                    class="bg"
                    elevation="5"
                    height="110px"
                    width="110px"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal white--text"
                        style="
                          height: 100%;
                          font-size: 13px;
                          color: white !important;
                          font-family: opensansregular !important;
                        "
                      >
                        {{ item.text }}
                      </div>
                    </v-expand-transition>
                    <v-layout wrap>
                      <v-flex xs12 pa-2 pt-3>
                        <v-img :src="item.img" contain height="80"></v-img>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-hover>
                <v-layout wrap justify-center>
                  <v-flex xs12 py-5>
                    <span
                      style="
                        font-family: scopeOneRegular;
                        color: #000000;
                        font-size: 16px;
                      "
                      >{{ item.name }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <span
                  style="
                    font-family: scopeOneRegular;
                    color: #000000;
                    font-size: 16px;
                  "
                  >Record, Store and Publish securely for the Environment
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
    </v-img>
  </div>
</template>
<script>
export default {
  data: () => ({
    appLoading: false,
    ServerError: false,
    items: [
      {
        img: require("./../../assets/icons/ar1.png"),
        name: "Bonds",
        text: "Issuers and certifiers of Green Bond and Loans",
      },
      {
        img: require("./../../assets/icons/ar2.png"),
        name: "Projects",
        text: "Nature-based solutions and nature conservation projects",
      },
      {
        img: require("./../../assets/icons/ar3.png"),
        name: "Assets",
        text: "Green asset tokenisation",
      },
      {
        img: require("./../../assets/icons/ar4.png"),
        name: "Disclosures",
        text: "Company, country-level climate disclosures",
      },
    ],
  }),
};
</script>
<style>
.bg {
  background-image: linear-gradient(to bottom, #6aee9fb2 0%, #7cb9e8b2 100%);
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1000 !important;
}
</style>
